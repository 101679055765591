import React from 'react';
import './validation-error-message.scss';

interface ValidationErrorMessageProps {
  error: string | undefined;
  touched: boolean | undefined;
}
export const ValidationErrorMessage: React.FC<ValidationErrorMessageProps> = (
  props: ValidationErrorMessageProps
) => {
  return (
    <div className="validation-error">
      {props.error && props.touched ? (
        <div className="error-content">{props.error}</div>
      ) : null}
    </div>
  );
};
