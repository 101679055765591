import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.scss';
import '../src/shared/interceptors/axios-request.interceptors';
import {
  SHOPIFY_STORE_URL,
  SHOPIFY_STOREFRONT_GRAPHQL_RELATIVE_URL,
  SHOPIFY_STOREFRONT_ACCESS_TOKEN,
} from './shared/configs/AppConfigs';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import App from './App';

const httpLink = createHttpLink({
  uri: `${SHOPIFY_STORE_URL}/${SHOPIFY_STOREFRONT_GRAPHQL_RELATIVE_URL}`,
});

const authLink = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token': SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
}));

const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
