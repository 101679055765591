import React from 'react';
import { Invitations } from '../features/invitations/Invitations';

import './invitations-page.scss';
import { Header } from '../shared/components/header/Header';
import { Footer } from '../shared/components/footer/Footer';

export const InvitationsPage: React.FC = () => {
  return (
    <div className="invitations-page app-flex-container">
      <Header />
      <Invitations />
      <Footer backgroundColor="#001446" />
    </div>
  );
};
