import { gql } from '@apollo/client';

export const AUTHENTICATE_CUSTOMER = gql`
  mutation authenticateCustomer($custCred: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $custCred) {
      customerUserErrors {
        field
        message
        code
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
    }
  }
`;
