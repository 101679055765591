import React from 'react';
import drinkWise from '../../../assets/drink-wise.svg';
import './footer.scss';

export const Footer: React.FC<{
  backgroundColor: string;
  isLoginPage?: boolean;
}> = (props) => {
  return (
    <>
      <div
        className={
          props.isLoginPage
            ? 'footer-container login-page-footer'
            : 'footer-container'
        }
        style={{ backgroundColor: props.backgroundColor }}
      >
        <div className="footer-container__lower">
          <div className="link-section">
            <div className="copyright">
              By submitting this form, you agree to the Privacy Policy
            </div>
            <div className="links">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://vanguardbottleshop.com.au/policies/privacy-policy"
              >
                <div>Privacy Policy</div>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://vanguardbottleshop.com.au/policies/terms-of-service"
              >
                <div>Terms & Conditions</div>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://drinkwise.org.au/"
                className="drinkwise"
              >
                <img src={drinkWise} />
              </a>
            </div>
          </div>
          <div className="license-section">
            © 2021, Vanguard Bottleshop. To experience this site you must be of
            legal drinking age in your country of residence. Vanguard Luxury
            Brands promotes responsible alcohol consumption practices at all
            times. NSW Packaged Liquor Licence Number LIQP770010184. NT
            Interstate Retailer Licence IRL0176. NSW | Liquor Act 2007: it is
            against the law to sell or supply alcohol to, or to obtain alcohol
            on behalf of, a person under the age of 18 years ACT | Liquor Act
            2010: it is an offence to supply alcohol to a person under the age
            of 18 years. Penalties apply. VIC | Warning: Victoria Liquor Control
            Reform Act 1998: it is an offence to supply alcohol to a person
            under the age of 18 years (penalty exceeds at $19,000), for a person
            under the age of 18 years to purchase or receive liquor (penalty
            exceeds $800). QLD | Under the Liquor Act 1992, it is an offence to
            supply liquor to a person under the age of 18 years. WA | Warning:
            Under the Liquor Control Act 1988, it is an offence: to sell or
            supply liquor to a person under the age of 18 years on licensed or
            regulated premises; or for a person under the age of 18 years to
            purchase, or attempt to purchase, liquor on licensed or regulated
            premises. TAS | Liquor Licensing Act 1990: it is an offence for
            liquor to be delivered to a person under the age of 18 years. SA |
            Liquor Licensing Act 1997: liquor must not be supplied to persons
            under 18 years of age. NT | Liquor Act 2010: it is against the law
            to sell or supply alcohol to, or to obtain alcohol on behalf of, a
            person under the age of 18 years.
          </div>
        </div>
      </div>
    </>
  );
};
