import React from 'react';
import { LoginForm } from '../../features/auth/LoginForm';
import cartIcon from '../../assets/icon-cart-white.svg';
import vanguardLogo from '../../assets/vanguard-logo-white.svg';
import { Footer } from '../../shared/components/footer/Footer';
import './bottle-shop.scss';

export const BottleShop: React.FC = () => {
  const onClickVanguard = () => {
    window.open('https://vanguardbottleshop.com.au', '_blank');
  };

  return (
    <>
      <div className="bottle-shop-container app-flex-container">
        <div className="header-section">
          <div className="link-section">
            <div className="icon-text" onClick={onClickVanguard}>
              <img src={cartIcon} />
              <div className="text">Go to Vanguard Bottleshop</div>
            </div>
          </div>
          <div className="logo-section">
            <img src={vanguardLogo} />
          </div>
        </div>
        <div className="login-center-section">
          <div className="description-login-section">
            <div className="heading">Vanguard Bottleshop</div>
            <div className="sub-heading">
              Vanguard Bottleshop is an exclusive online store for team members
              of Lion and Vanguard Luxury Brands, and their friends and family.
              Please login to invite your friends and family members.
            </div>
          </div>
          <div className="dialog-login-section">
            <div className="heading">Login to invite friends & family</div>
            <LoginForm />
          </div>
        </div>
        <Footer backgroundColor="transparent" isLoginPage={true} />
      </div>
    </>
  );
};
