import React from 'react';
import IInvitedCustomerModel from '../../shared/models/invited-customer-info-model';
import moment from 'moment';
import './invitation-table.scss';

interface IInvitationTableProps {
  customers: IInvitedCustomerModel[];
  showDeleteDialog: (customer: IInvitedCustomerModel) => void;
}

export const InvitationTable: React.FC<IInvitationTableProps> = (props) => {
  return (
    <div className="invitation-table">
      {props.customers && props.customers.length > 0 && (
        <table>
          <thead>
            <tr className="table-heading">
              <th colSpan={3}>Email</th>
              <th colSpan={1}>Date</th>
              <th colSpan={1}>Status</th>
              <th colSpan={1}>Action</th>
            </tr>
          </thead>
          <tbody>
            {props.customers!.map((customer, idx) => {
              return (
                <tr key={idx} style={{ borderBottom: '1px solid black' }}>
                  <td colSpan={3}>
                    <div className="email">{customer.email}</div>
                  </td>
                  <td colSpan={1}>
                    <div className="date">
                      {moment(customer.created_at).format('DD/MM/YYYY')}
                    </div>
                  </td>
                  <td colSpan={1}>
                    {customer.state == 'invited' ? (
                      <span className="invited-status">Invite pending</span>
                    ) : customer.state == 'disabled' ? (
                      <span className="disabled-status">Disabled</span>
                    ) : customer.state == 'declined' ? (
                      <span className="declined-status">Invite declined</span>
                    ) : (
                      <span className="accepted-status">Accepted</span>
                    )}
                  </td>
                  <td colSpan={1}>
                    {customer.state === 'invited' ||
                    customer.state === 'declined' ? (
                      <div
                        className="delete-action"
                        onClick={() => props.showDeleteDialog(customer)}
                      >
                        Delete
                      </div>
                    ) : customer.state === 'disabled' ? (
                      <div className="contact-action">Contact Vanguard</div>
                    ) : (
                      <div></div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
