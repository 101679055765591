import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { AuthProvider } from './shared/context/auth.context';
import { AuthRouteGuard } from './shared/services/AuthRouteGuard';
import { HomePage } from './pages/HomePage';
import { InvitationsPage } from './pages/InvitationsPage';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import './styles/global.scss';

Amplify.configure(awsconfig);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <div className="app-flex-container">
        <Router>
          <Switch>
            <AuthRouteGuard
              path="/invitations"
              exact
              component={InvitationsPage}
            />
            <Route path="/" exact component={HomePage} />
          </Switch>
        </Router>
      </div>
    </AuthProvider>
  );
};

export default App;
