import { ButtonType } from './button-type.enum';
import React from 'react';
import './button.scss';

interface CustomButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  styleType: ButtonType;
}

const Button = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  (props, ref) => {
    let buttonTypeClass = '';

    switch (props.styleType) {
      case ButtonType.PRIMARY:
        buttonTypeClass = 'button__primary';
        break;
      case ButtonType.PRIMARY_REVERSE:
        buttonTypeClass = 'button__primary-reverse';
        break;
      case ButtonType.SECONDARY:
        buttonTypeClass = 'button__secondary';
        break;
      case ButtonType.TERTIARY:
        buttonTypeClass = 'button__tertiary';
        break;
      default:
        break;
    }

    return (
      <button
        type={props.type === 'button' ? 'button' : 'submit'}
        className={`button ${buttonTypeClass} ${props.className}`}
        disabled={props.disabled}
        onClick={props.onClick}
        ref={ref}
      >
        {props.children}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
