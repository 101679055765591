import React from 'react';
import { BottleShop } from '../features/home/BottleShop';
import './home-page.scss';

export const HomePage: React.FC = () => {
  return (
    <div className="home-page app-flex-container">
      <BottleShop />
    </div>
  );
};
