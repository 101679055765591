import React, { useState, useEffect, useCallback } from 'react';
import ICustomerModel from '../models/customer-info-model';
import { useLazyQuery } from '@apollo/client';
import ICustomerInfoResponseDTO from '../models/customer-info-response.dto';
import ICustomerInfoRequestDTO from '../models/customer-info-request.dto';
import { GET_CUSTOMER } from '../gql/customer.gql';

interface AuthContextInterface {
  isAuth: boolean;
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
  loggedInCustomer: ICustomerModel;
  setLoggedInCustomer: React.Dispatch<React.SetStateAction<ICustomerModel>>;
}

export const AuthContext = React.createContext<AuthContextInterface | null>(
  null
);

export const AuthProvider: React.FC = ({ children }) => {
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [loggedInCustomer, setLoggedInCustomer] = useState<ICustomerModel>({
    email: '',
    firstName: '',
    lastName: '',
    id: '',
    __typename: '',
  });
  const [fetchCustomerData, { data: customerData }] = useLazyQuery<
    ICustomerInfoResponseDTO,
    ICustomerInfoRequestDTO
  >(GET_CUSTOMER, {
    notifyOnNetworkStatusChange: true,
  });

  const getLoggedInCustomerData = useCallback(
    (customerAccessToken: string) => {
      const variables: ICustomerInfoRequestDTO = {
        customerAccessToken,
      };
      fetchCustomerData({ variables });
    },
    [fetchCustomerData]
  );

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      getLoggedInCustomerData(accessToken);
    }
  }, [getLoggedInCustomerData]);

  useEffect(() => {
    if (customerData && customerData.customer) {
      setLoggedInCustomer(customerData.customer);
      setIsAuth(true);
    }
  }, [customerData]);

  return (
    <AuthContext.Provider
      value={{ isAuth, setIsAuth, loggedInCustomer, setLoggedInCustomer }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextInterface =>
  React.useContext(AuthContext) as AuthContextInterface;
