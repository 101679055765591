import React from 'react';
import IInvitedCustomerModel from '../../shared/models/invited-customer-info-model';
import './invitation-list.scss';
import moment from 'moment';

interface IInvitationListProps {
  customers: IInvitedCustomerModel[];
  showDeleteDialog: (customer: IInvitedCustomerModel) => void;
}

export const InvitationList: React.FC<IInvitationListProps> = (props) => {
  return (
    <div className="invitation-list">
      {props.customers && props.customers.length > 0 && (
        <>
          <div className="invitation-list__headers">
            <div>Email</div>
            <div>Status</div>
          </div>
          <div className="invitation-list__body">
            {props.customers.map((customer, idx) => {
              return (
                <div className="line-item" key={idx}>
                  <div className="line-item-top">
                    <div className="email">{customer.email}</div>
                    {customer.state == 'invited' ? (
                      <div className="invited-status">Invite pending</div>
                    ) : customer.state == 'disabled' ? (
                      <div className="disabled-status">Disabled</div>
                    ) : customer.state == 'declined' ? (
                      <div className="declined-status">Invite declined</div>
                    ) : (
                      <div className="accepted-status">Accepted</div>
                    )}
                  </div>
                  <div className="line-item-bottom">
                    <div className="date">
                      {moment(customer.created_at).format('DD/MM/YYYY')}
                    </div>
                    {customer.state === 'invited' ||
                    customer.state === 'declined' ? (
                      <div
                        className="delete-action"
                        onClick={() => props.showDeleteDialog(customer)}
                      >
                        Delete
                      </div>
                    ) : customer.state === 'disabled' ? (
                      <div className="contact-action">Contact Vanguard</div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
