import * as Yup from 'yup';

export const InviteValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email format is incorrect')
    .required('Email address is required')
    .max(320, 'Email is too long'),
  firstName: Yup.string()
    .required('First name is required')
    .max(320, 'First name is too long'),
  lastName: Yup.string()
    .required('Last name is required')
    .max(320, 'Last name is too long'),
  consent: Yup.bool()
    .required('Please confirm that you have consent')
    .oneOf([true], 'Please confirm that you have consent'),
});
