import React, { useState, useRef } from 'react';
import Button from '../../shared/components/customButton/Button';
import { ButtonType } from '../../shared/components/customButton/button-type.enum';
import ClipLoader from 'react-spinners/ClipLoader';
import IInvitedCustomerModel from '../../shared/models/invited-customer-info-model';
import './delete-modal.scss';
import { API } from 'aws-amplify';

interface IDeleteModalProps {
  getInvitedCustomers: () => void;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  customerToBeDeleted: IInvitedCustomerModel | undefined;
  customers: IInvitedCustomerModel[];
  setCustomers: React.Dispatch<
    React.SetStateAction<IInvitedCustomerModel[] | undefined>
  >;
}

export const DeleteModal: React.FC<IDeleteModalProps> = (props) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const confirmRef = useRef<HTMLButtonElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const deleteCustomer = async () => {
    const { id } = props.customerToBeDeleted!;
    setDeleteLoading(true);
    await API.del('apiVanguard', `/customer`, {
      body: { id },
    }).then(
      () => {
        setDeleteLoading(false);
        setDeleteError(false);
        props.setShowDeleteModal(false);
        const updatedCustomers = props.customers.filter(
          (customer) => customer.id !== props.customerToBeDeleted!.id
        );
        props.setCustomers(updatedCustomers);
      },
      () => {
        setDeleteLoading(false);
        setDeleteError(true);
      }
    );
  };

  return (
    <div className="delete-modal-body">
      <div className="message">
        The recipient will not be able to accept your invite, but you can invite
        them again later.
      </div>
      <Button
        styleType={ButtonType.PRIMARY}
        type="button"
        className="delete-confirm-btn"
        ref={confirmRef}
        onClick={() => deleteCustomer()}
      >
        Yes
      </Button>
      <Button
        styleType={ButtonType.TERTIARY}
        type="button"
        className="delete-cancel-btn"
        ref={cancelRef}
        onClick={() => props.setShowDeleteModal(false)}
      >
        No
      </Button>
      <ClipLoader
        color="#001446"
        loading={deleteLoading}
        css="margin: 0 auto; display: block; margin-top: 16px"
        size={20}
      />
      {deleteError && (
        <div className="error-message">Deletion Failed. Contact Vanguard.</div>
      )}
    </div>
  );
};
