import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../context/auth.context';

export const AuthRouteGuard: React.FC<{
  component: React.FC;
  path: string;
  exact?: boolean;
}> = (parentProps) => {
  const { isAuth } = useAuthContext();

  return (
    <Route
      exact={parentProps.exact}
      render={() =>
        isAuth || sessionStorage.getItem('accessToken') ? (
          <parentProps.component />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
