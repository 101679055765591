import React, { useState, useRef } from 'react';
import Button from '../../shared/components/customButton/Button';
import { ButtonType } from '../../shared/components/customButton/button-type.enum';
import ClipLoader from 'react-spinners/ClipLoader';
import { API } from 'aws-amplify';
import { useAuthContext } from '../../shared/context/auth.context';
import './invite-modal.scss';
import { InviteValidationSchema } from './InviteValidationSchema';
import { Formik, Field } from 'formik';
import {
  FormInput,
  FormDate,
} from '../../shared/components/customFormField/FormField';
import IInvitedCustomerModel from '../../shared/models/invited-customer-info-model';
import { ValidationErrorMessage } from '../../shared/components/customErrorMessage/ValidationErrorMessage';

interface IInviteModalProps {
  getInvitedCustomers: () => void;
  setShowInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  customers: IInvitedCustomerModel[];
  setCustomers: React.Dispatch<
    React.SetStateAction<IInvitedCustomerModel[] | undefined>
  >;
}

interface InviteFormModel {
  email: string;
  firstName: string;
  lastName: string;
  dob: string;
}

export const InviteModal: React.FC<IInviteModalProps> = (props) => {
  const [inviteLoading, setInviteLoading] = useState(false);
  const [inviteError, setInviteError] = useState(false);
  const [inviteErrorMessage, setInviteErrorMessage] = useState('');
  const confirmRef = useRef<HTMLButtonElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { loggedInCustomer } = useAuthContext();

  const createAndInviteCustomer = async (values: InviteFormModel) => {
    setInviteLoading(true);
    await API.post('apiVanguard', '/customer', {
      body: {
        customer: {
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          note: `DOB:${values.dob}`,
          tags: loggedInCustomer.id,
        },
      },
    }).then(
      async (res) => {
        await API.post('apiVanguard', '/customer/invite', {
          body: { id: res.customer.id },
        }).then(
          () => {
            const updatedCustomers = [
              {
                email: res.customer.email,
                id: res.customer.id,
                state: 'invited',
                created_at: res.customer.created_at,
                tags: res.customer.tags,
                note: res.customer.note,
                first_name: res.customer.first_name,
                last_name: res.customer.last_name,
              },
              ...props.customers!,
            ] as IInvitedCustomerModel[];
            props.setCustomers(updatedCustomers);
            setInviteLoading(false);
            setInviteError(false);
            props.setShowInviteModal(false);
          },
          () => {
            const updatedCustomers = [
              {
                email: res.customer.email,
                id: res.customer.id,
                state: 'disabled',
                created_at: res.customer.created_at,
                tags: res.customer.tags,
                note: res.customer.note,
                first_name: res.customer.first_name,
                last_name: res.customer.last_name,
              },
              ...props.customers!,
            ] as IInvitedCustomerModel[];
            props.setCustomers(updatedCustomers);
            setInviteLoading(false);
            setInviteError(true);
            setInviteErrorMessage(
              'Invitation email sending failed. Contact Vanguard.'
            );
          }
        );
      },
      (err) => {
        setInviteError(true);
        setInviteLoading(false);
        if (err.response.status == 422) {
          setInviteErrorMessage('This person already has an account');
        } else {
          setInviteErrorMessage('Customer creation failed. Contact Vanguard.');
        }
      }
    );
  };

  return (
    <div className="invite-modal-body">
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          dob: '',
          consent: false,
        }}
        validationSchema={InviteValidationSchema}
        validateOnMount
        onSubmit={async (values) => {
          await createAndInviteCustomer(values);
        }}
      >
        {({
          errors,
          touched,
          isValid,
          handleSubmit,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormInput
              id="email"
              label="Email Address"
              name="email"
              error={errors.email}
              touched={touched.email}
            />
            <FormInput
              id="firstName"
              label="First Name"
              name="firstName"
              error={errors.firstName}
              touched={touched.firstName}
            />
            <FormInput
              id="lastName"
              label="Last Name"
              name="lastName"
              error={errors.lastName}
              touched={touched.lastName}
            />
            <FormDate
              id="dob"
              label="Date of Birth"
              name="dob"
              placeholder="DD/MM/YYYY"
              error={errors.dob}
              touched={touched.dob}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="checkbox-form-field">
              <label className="custom-checkbox">
                <Field
                  id="consent"
                  name="consent"
                  type="checkbox"
                  error={errors.consent}
                  touched={touched.consent}
                  onClick={() => {
                    setFieldTouched('consent', true);
                  }}
                />
                <span>
                  I confirm that I have obtained consent from my nominated
                  friends and family members to provide their email address to
                  Vanguard for the purpose of this offer
                </span>
              </label>
              <ValidationErrorMessage
                error={errors.consent}
                touched={touched.consent}
              />
            </div>
            <Button
              styleType={ButtonType.PRIMARY}
              type="submit"
              className="invite-confirm-btn"
              disabled={!isValid || isSubmitting}
              ref={confirmRef}
            >
              Send
            </Button>
            <Button
              styleType={ButtonType.TERTIARY}
              type="button"
              className="invite-cancel-btn"
              ref={cancelRef}
              onClick={() => props.setShowInviteModal(false)}
            >
              Cancel
            </Button>
            <ClipLoader
              color="#001446"
              loading={inviteLoading}
              css="margin: 0 auto; display: block; margin-top: 16px"
              size={20}
            />
            {inviteError && (
              <div className="error-message">{inviteErrorMessage}</div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
