import React, { useEffect, useState, useCallback } from 'react';
import './invitations.scss';
import Button from '../../shared/components/customButton/Button';
import { ButtonType } from '../../shared/components/customButton/button-type.enum';
import { API } from 'aws-amplify';
import { useAuthContext } from '../../shared/context/auth.context';
import _ from 'underscore';
import { CustomModal } from '../../shared/components/customModal/CustomModal';
import { DeleteModal } from './DeleteModal';
import { InvitationTable } from './InvitationTable';
import { InvitationList } from './InvitationList';
import { InviteModal } from './InviteModal';
import IInvitedCustomerModel from '../../shared/models/invited-customer-info-model';
import { isMobileOnly } from 'react-device-detect';

export const Invitations: React.FC = () => {
  const [customers, setCustomers] = useState<IInvitedCustomerModel[]>();
  const [totalAcceptedCustomers, setTotalAcceptedCustomers] = useState(0);
  const [totalInvitedCustomers, setTotalInvitedCustomers] = useState(0);
  const { loggedInCustomer } = useAuthContext();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [
    customerToBeDeleted,
    setCustomerToBeDeleted,
  ] = useState<IInvitedCustomerModel>();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [invitedCustomersFetchError, setInvitedCustomersFetchError] = useState(
    false
  );

  const getInvitedCustomers = useCallback(async () => {
    if (loggedInCustomer.id)
      await API.get(
        'apiVanguard',
        `/customer/${loggedInCustomer.id.replace(/^\D+/g, '')}`,
        {}
      ).then(
        (res) => {
          setCustomers(res.customers);
          setInvitedCustomersFetchError(false);
        },
        () => {
          setInvitedCustomersFetchError(true);
        }
      );
  }, [loggedInCustomer]);

  useEffect(() => {
    getInvitedCustomers();
    window.scrollTo(0, 0);
  }, [loggedInCustomer, getInvitedCustomers]);

  useEffect(() => {
    const enabledCustomers = _.filter(customers! as any[], (customer) => {
      if (customer.state == 'enabled') {
        return true;
      }
    });
    const invitedCustomers = _.filter(customers! as any[], (customer) => {
      if (customer.state !== 'declined') {
        return true;
      }
    });
    setTotalAcceptedCustomers(enabledCustomers.length);
    setTotalInvitedCustomers(invitedCustomers.length);
  }, [customers]);

  const showDeleteDialog = (customer: IInvitedCustomerModel) => {
    setCustomerToBeDeleted(customer);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="invitations-container">
        <div className="invitations-container__greeting">
          Hi, {loggedInCustomer.firstName} {loggedInCustomer.lastName}
        </div>
        <div className="invitations-container__invite-text">
          Send invites and track their status below. You can invite up to 10
          friends and family members above the age of 18.
        </div>
        <div className="invitations-container__heading">My invites</div>
        <div
          className="invitations-container__invite-details"
          style={{
            justifyContent:
              customers && customers.length > 0 ? 'space-between' : 'center',
          }}
        >
          {customers && customers.length > 0 && (
            <div className="invite-numbers">
              <div className="total-invited">
                <div className="total-invited-title">Total Invited</div>
                {customers ? (
                  <div className="total-invited-value">
                    {totalInvitedCustomers}
                  </div>
                ) : (
                  <div className="total-invited-value">0</div>
                )}
              </div>
              <div className="total-accepted">
                <div className="total-accepted-title">Total Accepted</div>
                <div className="total-accepted-value">
                  {totalAcceptedCustomers}
                </div>
              </div>
            </div>
          )}
          {customers && totalInvitedCustomers >= 10 ? (
            <Button
              styleType={ButtonType.PRIMARY}
              type="button"
              className="invite-used-btn"
              disabled={true}
            >
              Invites used up
            </Button>
          ) : (
            <Button
              styleType={ButtonType.PRIMARY}
              type="button"
              className="invite-btn"
              onClick={() => setShowInviteModal(true)}
            >
              Send a new invite
            </Button>
          )}
        </div>
        {!invitedCustomersFetchError ? (
          isMobileOnly ? (
            <InvitationList
              customers={customers!}
              showDeleteDialog={showDeleteDialog}
            />
          ) : (
            <InvitationTable
              customers={customers!}
              showDeleteDialog={showDeleteDialog}
            />
          )
        ) : (
          <div className="error-message">
            Error fetching invited customer details
          </div>
        )}
      </div>
      <CustomModal
        show={showDeleteModal}
        showCloseIcon={true}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        title="Are you sure?"
      >
        <DeleteModal
          getInvitedCustomers={getInvitedCustomers}
          setShowDeleteModal={setShowDeleteModal}
          customerToBeDeleted={customerToBeDeleted}
          customers={customers!}
          setCustomers={setCustomers}
        />
      </CustomModal>
      <CustomModal
        show={showInviteModal}
        showCloseIcon={true}
        onHide={() => setShowInviteModal(false)}
        backdrop="static"
        title="Send email invitation to"
      >
        <InviteModal
          getInvitedCustomers={getInvitedCustomers}
          setShowInviteModal={setShowInviteModal}
          customers={customers!}
          setCustomers={setCustomers}
        />
      </CustomModal>
    </>
  );
};
