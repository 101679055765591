import React from 'react';
import Modal from 'react-bootstrap/Modal';
import iconClose from '../../../assets/icon-close.svg';
import './custom-modal.scss';

export const CustomModal: React.FC<{
  show: boolean;
  showCloseIcon: boolean;
  onHide: () => void;
  title: string;
  backdrop: 'static' | boolean;
  dialogClassName?: string;
}> = (props) => {
  const {
    onHide,
    showCloseIcon,
    backdrop,
    title,
    dialogClassName,
    ...rest
  } = props;

  return (
    <Modal
      {...rest}
      backdrop={backdrop}
      keyboard={false}
      dialogClassName={dialogClassName}
      centered
    >
      <Modal.Header>
        <div
          className="close-icon"
          onClick={onHide}
          style={{ display: showCloseIcon ? 'flex' : 'none' }}
        >
          <img src={iconClose} />
        </div>
        <div className="heading">{title}</div>
      </Modal.Header>
      <Modal.Body>{props.children}</Modal.Body>
    </Modal>
  );
};
