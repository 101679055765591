import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import {
  FormEmail,
  FormPassword,
} from '../../shared/components/customFormField/FormField';
import { LoginValidationSchema } from './LoginValidationSchema';
import Button from '../../shared/components/customButton/Button';
import { ButtonType } from '../../shared/components/customButton/button-type.enum';
import ClipLoader from 'react-spinners/ClipLoader';
import { useAuthContext } from '../../shared/context/auth.context';
import { useHistory } from 'react-router-dom';
import ILoginResponseDTO from '../../shared/models/login-response.dto';
import ILoginRequestDTO from '../../shared/models/login-request.dto';
import ICustomerInfoResponseDTO from '../../shared/models/customer-info-response.dto';
import ICustomerInfoRequestDTO from '../../shared/models/customer-info-request.dto';
import { AUTHENTICATE_CUSTOMER } from '../../shared/gql/user-authentication.gql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_CUSTOMER } from '../../shared/gql/customer.gql';
import './login-form.scss';

export const LoginForm: React.FC = () => {
  const [
    authenticateUser,
    { loading: loginAPILoading, data: authData },
  ] = useMutation<ILoginResponseDTO, ILoginRequestDTO>(AUTHENTICATE_CUSTOMER);

  const [fetchCustomerData, { data: customerData }] = useLazyQuery<
    ICustomerInfoResponseDTO,
    ICustomerInfoRequestDTO
  >(GET_CUSTOMER, {
    notifyOnNetworkStatusChange: true,
  });

  const [loginAPIError, setLoginAPIError] = useState(false);
  const { setIsAuth, setLoggedInCustomer } = useAuthContext();
  const history = useHistory();
  const accessTokenRef = React.useRef<string>('');
  const submitRef = useRef<HTMLButtonElement>(null);

  const getLoggedInCustomerData = useCallback(
    (customerAccessToken: string) => {
      const variables: ICustomerInfoRequestDTO = {
        customerAccessToken,
      };

      fetchCustomerData({ variables });
    },
    [fetchCustomerData]
  );

  useEffect(() => {
    if (authData) {
      if (authData.customerAccessTokenCreate.customerAccessToken) {
        accessTokenRef.current =
          authData.customerAccessTokenCreate.customerAccessToken.accessToken;
        getLoggedInCustomerData(
          authData.customerAccessTokenCreate.customerAccessToken.accessToken
        );
      } else {
        setLoginAPIError(true);
      }
    }
  }, [authData, setIsAuth, history, getLoggedInCustomerData]);

  useEffect(() => {
    if (customerData) {
      if (customerData.customer) {
        setLoggedInCustomer(customerData.customer);
        sessionStorage.setItem('accessToken', accessTokenRef.current!);
        setLoginAPIError(false);
        setIsAuth(true);
        history.push('/invitations');
      } else {
        setLoginAPIError(true);
      }
    }
  }, [customerData, history, setIsAuth, setLoggedInCustomer]);

  return (
    <div className="login-form">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginValidationSchema}
        validateOnMount
        onSubmit={async (values) => {
          setLoginAPIError(false);
          const variables: ILoginRequestDTO = {
            custCred: values,
          };
          await authenticateUser({ variables });
        }}
      >
        {({ errors, touched, isValid, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormEmail
              id="email"
              label="Email Address"
              name="email"
              error={errors.email}
              touched={touched.email}
            />
            <FormPassword
              id="password"
              label="Password"
              name="password"
              error={errors.password}
              touched={touched.password}
            />
            <div className="forgot-password-text">
              Please visit the main website if you need to reset your password
            </div>
            <Button
              styleType={ButtonType.PRIMARY}
              type="submit"
              className="login-btn"
              disabled={!isValid || isSubmitting}
              ref={submitRef}
            >
              Login
            </Button>
            <ClipLoader
              color="#001446"
              loading={loginAPILoading}
              css="margin: 0 auto; display: block; margin-top: 12px"
              size={20}
            />
            {loginAPIError && (
              <div className="error-message">
                Login Failed. Check credentials.
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
