import React, { useState } from 'react';
import { useField, Field } from 'formik';
import { ValidationErrorMessage } from '../customErrorMessage/ValidationErrorMessage';
import iconShowPassword from '../../../assets/icon-show-password.svg';
import iconHidePassword from '../../../assets/icon-hide-password.svg';
import moment from 'moment';
import './form-field.scss';

interface IFormInputProps {
  id: string;
  name: string;
  placeholder?: string;
  label: string;
  disabled?: boolean;
  error?: string;
  touched?: boolean;
  handleBlur?: (e: React.SyntheticEvent<EventTarget>) => void;
  handleChange?: (e: React.SyntheticEvent<EventTarget>) => void;
}

export const FormInput: React.FC<IFormInputProps> = (props) => {
  const [field] = useField(props);
  return (
    <div
      className={`form-field ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <label>{props.label}</label>
      <Field
        {...props}
        {...field}
        type="text"
        value={field.value == null ? '' : field.value}
      />
      <ValidationErrorMessage error={props.error} touched={props.touched} />
    </div>
  );
};

export const FormDate: React.FC<IFormInputProps> = (props) => {
  const [field] = useField(props);
  const [fieldFocus, setFieldFocus] = useState(false);
  const validateDate = (value: string) => {
    let error;
    if (value === '') {
      error = 'Date of birth is required';
    } else if (
      !/^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/]\d{4}$/.test(value)
    ) {
      error = 'Date of birth format is incorrect';
    } else {
      const age = moment(new Date()).diff(
        moment(value, 'DD/MM/YYYY'),
        'years',
        true
      );
      if (age < 18) {
        error = 'Invited user should be 18+';
      } else if (age > 100) {
        error = 'Age should be less than 99 years';
      }
    }
    return error;
  };

  const onCustomChange = (e: React.SyntheticEvent<EventTarget>) => {
    setFieldFocus(true);
    props.handleChange!(e);
  };

  const onCustomBlur = (e: React.SyntheticEvent<EventTarget>) => {
    setFieldFocus(false);
    props.handleBlur!(e);
  };

  return (
    <div
      className={`form-field ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <label>{props.label}</label>
        <div
          className="date-format-label"
          style={{
            display:
              field.value.length > 0 && (fieldFocus || props.error)
                ? 'flex'
                : 'none',
          }}
        >
          DD/MM/YYYY
        </div>
      </div>

      <Field
        {...props}
        {...field}
        type="text"
        validate={validateDate}
        onChange={(e: React.SyntheticEvent<EventTarget>) => onCustomChange(e)}
        onBlur={(e: React.SyntheticEvent<EventTarget>) => {
          onCustomBlur(e);
        }}
      />
      <ValidationErrorMessage error={props.error} touched={props.touched} />
    </div>
  );
};

export const FormEmail: React.FC<IFormInputProps> = (props) => {
  const [field] = useField(props);

  const validateEmail = (value: string) => {
    let error;
    if (
      !value.includes('@lionco.com') &&
      !value.includes('@vanguardluxurybrands.com') &&
      !value.includes('@maltshovel.com.au') &&
      !value.includes('@littlecreatures.com.au') &&
      !value.includes('@thinkerbell.com') &&
      !value.includes('@umww.com') &&
      !value.includes('@affinity.ad') &&
      !value.includes('@fourpillarsgin.com.au') &&
      !value.includes('@stoneandwood.com.au') &&
      !value.includes('@twobirdsbrewing.com.au') &&
      !value.includes('@fixationbrewing.com.au') &&
      !value.includes('@fermentum.com.au')
    ) {
      error =
        'Only Lion and Lion partner accounts can invite family & friends';
    }
    return error;
  };
  return (
    <div
      className={`form-field ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <label>{props.label}</label>
      <Field
        {...props}
        {...field}
        validate={validateEmail}
        type="text"
        value={field.value == null ? '' : field.value}
      />
      <ValidationErrorMessage error={props.error} touched={props.touched} />
    </div>
  );
};

export const FormPassword: React.FC<IFormInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [field] = useField(props);
  return (
    <div
      className={`form-field ${
        props.error && props.touched ? 'input-error' : ''
      }`}
    >
      <div className="password-label-and-link">
        <label>{props.label}</label>
        <div
          className="toggle-link"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <img src={iconHidePassword} />
          ) : (
            <img src={iconShowPassword} />
          )}
          {showPassword ? <div>Hide</div> : <div>Show</div>}
        </div>
      </div>

      <Field {...props} {...field} type={showPassword ? 'text' : 'password'} />
      <ValidationErrorMessage error={props.error} touched={props.touched} />
    </div>
  );
};
