import axios from 'axios';

axios.interceptors.request.use(
  (req) => {
    req.headers['Content-Type'] = 'application/json';
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
