import React from 'react';
import { useHistory } from 'react-router-dom';
import vanguardLogo from '../../../assets/vanguard-logo.svg';
import './header.scss';
import cartIcon from '../../../assets/icon-cart.svg';
import logoutIcon from '../../../assets/icon-logout.svg';
import { useAuthContext } from '../../../shared/context/auth.context';
import { isMobile } from 'react-device-detect';

export const Header: React.FC = () => {
  const history = useHistory();
  const { setIsAuth } = useAuthContext();

  const logout = () => {
    sessionStorage.removeItem('accessToken');
    setIsAuth(false);
    history.push('/');
  };

  const onClickVanguard = () => {
    window.open('https://vanguardbottleshop.com.au', '_blank');
  };

  return (
    <div className="header-container">
      <div className="main-section">
        <div className="link-section">
          <div className="icon-text" onClick={onClickVanguard}>
            <img src={cartIcon} />
            <div className="text">
              {!isMobile ? 'Go to Vanguard Bottleshop' : 'Shop'}
            </div>
          </div>
        </div>
        <div className="logo-section">
          <img src={vanguardLogo} />
        </div>
        <div className="logout-section">
          <div className="icon-text" onClick={logout}>
            <img src={logoutIcon} />
            <div className="text">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};
